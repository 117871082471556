body {
    background: #03a9f4;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

section {
    margin: 5;
    padding: 3;
}

button {
    border-radius: 15px;
    font-size: 1.1em;
    font-weight: 600;
    border: 0;
    cursor: pointer;
}

hr {
    margin: 0 10;
    padding-left: 10px;
}

a.button {
    padding: 10 20;
    border-radius: 10px;
    margin: 4px;
    color: white;
}